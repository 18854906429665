import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import { ArrowBack as BackIcon } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import React from "react";
import { companyFormValueSchema } from "../schema/company-form.schema";
import SwitchController from "../../../components/SwitchController";
import TextFieldController from "../../../components/TextFieldController";

export function CompanyFormPopup({ isOpen, defaultValues, onSubmit, onClose }) {
  const methods = useForm({
    values: defaultValues,
    mode: "all",
  });

  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <FormProvider {...methods}>
          <form>
            <DialogTitle>
              <Grid item xs={12} className={"sub-action"}>
                <Button onClick={() => onClose()}>
                  <BackIcon />
                </Button>
                <Typography variant={"h2"}>
                  {defaultValues?.isNew
                    ? "Company - New"
                    : `Company - ${defaultValues?.company.name}`}
                </Typography>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12}>
                      <Typography variant={"h2"}>General</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12}>
                          <TextFieldController
                            name={"company.code"}
                            label={"Code"}
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldController
                            name={"company.name"}
                            label={"Name"}
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldController
                            name={"company.apiEndpoint"}
                            label={"API Endpoint"}
                            required
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12}>
                      <Typography variant={"h2"}>Project</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12}>
                          <TextFieldController
                            name={"company.navLinkedCustomerNo"}
                            label={"Customer No."}
                            required
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextFieldController
                            name={"company.deptstorecode"}
                            label={"Department Store Code"}
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldController
                            name={"company.salesperson"}
                            label={"Salesperson"}
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldController
                            name={"company.gstbuspostgrp"}
                            label={"GST Group"}
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <SwitchController
                            name={"company.status"}
                            label={"Enable"}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="action">
              <Button
                onClick={methods.handleSubmit(onSubmit)}
                type={"submit"}
                className={"primary"}
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
}
