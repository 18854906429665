import React, { useState, useEffect } from "react";
import "./Styles.css";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import WebcamDialog from "../WebcamDialog";
import dataURItoBlob, {
  dataURItoFile,
} from "../../helper/convert-data-url-to-file";

const ImageUploadPreviewComponent = ({
  savedImages,
  maxNoOfImages = 0,
  note = "Note: Please shoot picture of the Aircon/Bracket Hole/Power Point/Brand/Compressor",
  readOnly = false,
  onAddImage,
  onRemoveImage,
  isWebcam,
  isWebcamOpen,
  handleCloseWebcam,
  handleOpenWebcam,
}) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const initialFiles = savedImages.map((image) => ({
      id: image.id,
      url:
        image.cloudUrl.length === 0
          ? URL.createObjectURL(image.image)
          : image.cloudUrl,
    }));
    setFiles(initialFiles);
  }, [savedImages]);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (readOnly) {
      return;
    }

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const newFiles = [...files];
      for (let j = 0; j < e.dataTransfer.files.length; j++) {
        if (!e.dataTransfer.files[j].type.includes("image/")) {
          continue;
        }

        const id = Math.floor(Math.random() * 10000);
        newFiles.push({
          id,
          url: URL.createObjectURL(e.dataTransfer.files[j]),
        });
        onAddImage(id, e.dataTransfer.files[j].name, e.dataTransfer.files[j]);
      }
      setFiles(newFiles);
    }
  };

  const handleFileUpload = (e) => {
    if (readOnly) {
      return;
    }

    const newFiles = [...files];
    for (let j = 0; j < e.target.files.length; j++) {
      const id = Math.floor(Math.random() * 10000);
      newFiles.push({ id, url: URL.createObjectURL(e.target.files[j]) });
      onAddImage(id, e.target.files[j].name, e.target.files[j]);
    }
    setFiles(newFiles);
  };

  const onAddImageInWebcam = async (image) => {
    if (readOnly) {
      return;
    }

    const newFiles = [...files];
    const id = Math.floor(Math.random() * 10000);
    const fileName = `${id}-${new Date().getTime()}.png`;
    const file = dataURItoFile(image, fileName);

    newFiles.push({ id, url: URL.createObjectURL(file) });
    onAddImage(id, file.name, file);
    setFiles(newFiles);
  };

  const removeImage = (id) => {
    const newFiles = files.filter((file) => file.id !== id);
    setFiles(newFiles);
    onRemoveImage(id);
  };

  const previewDocument = (imageUrl) => {
    window.open(imageUrl);
  };

  return (
    <>
      <div className="form-group">
        <input
          type="file"
          className="form-control"
          id="input-file-upload"
          accept="image/*"
          onChange={handleFileUpload}
          multiple={maxNoOfImages > 0 ? false : true}
        />
      </div>

      <Grid
        container
        className={"form"}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} className={"sub-action"}>
            <Typography variant={"h2"}>Photos</Typography>
            {!readOnly && (
              <Button
                type={"button"}
                onClick={() => {
                  if (isWebcam) {
                    handleOpenWebcam();
                  } else {
                    document.getElementById("input-file-upload").click();
                  }
                }}
                disabled={
                  maxNoOfImages > 0 && files.length == maxNoOfImages
                    ? true
                    : false
                }
                className={
                  maxNoOfImages > 0 && files.length == maxNoOfImages
                    ? "disabled_color"
                    : ""
                }
              >
                <AddIcon />
              </Button>
            )}
          </Grid>

          {!readOnly && (
            <>
              <Grid item xs={12} className={"note"}>
                {note}
              </Grid>
              <Grid item xs={12} className={"tile"}>
                <Typography className={"no-record"}>
                  No Records Found
                </Typography>
              </Grid>
            </>
          )}

          {readOnly && files.length == 0 && (
            <Grid item xs={12} className={"tile"}>
              <Typography className={"no-record"}>No Records Found</Typography>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            className={files.length == 0 ? "tile hide-this-component" : "tile"}
          >
            <ImageList sx={{ width: 1, height: 1 }} cols={4} gap={8}>
              {files.map((item) => (
                <ImageListItem key={item.id}>
                  <IconButton
                    className={
                      readOnly ? "hide-this-component" : "btnRemoveImage"
                    }
                    onClick={() => removeImage(item.id)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                  <img
                    className="img_preview"
                    src={item.url}
                    onClick={() => previewDocument(item.url)}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
        </Grid>
      </Grid>

      <WebcamDialog
        isOpen={isWebcamOpen}
        handleClose={handleCloseWebcam}
        onAddImage={onAddImageInWebcam}
      />
    </>
  );
};

export default ImageUploadPreviewComponent;
